// src/pages/PrivacyPolicy.js
import React from 'react';

import Header from './../pages/parts/Header';
import Footer from './../pages/parts/Footer';

import { Helmet } from 'react-helmet';
const Terms = () => {
  return (
    <>

      <Helmet>
        <title>Digime - Terms & Conditions</title>
      </Helmet>

      <Header className="page-header mainHeader" />
      <div class="container page-top-section">
        <article style={{ padding: '20px' }}>
          <header>
            <h1>Terms and Conditions</h1>
            <p><strong>Effective Date:</strong> 12 November 2024</p>
          </header>

          <section>
            <h2>1. Introduction</h2>
            <p>Welcome to Digime Web Solutions. These Terms and Conditions ("Terms") govern your use of our website and services. By accessing or using our services, you agree to comply with these Terms.</p>
          </section>

          <section>
            <h2>2. Services Provided</h2>
            <p>Digime Web Solutions offers web design and development services, including creating custom websites and providing ongoing support and maintenance.</p>
          </section>

          <section>
            <h2>3. User Responsibilities</h2>
            <p>As a user, you are responsible for the accuracy of the information you provide. You must not use our services for any illegal activities or in a way that violates any laws.</p>
          </section>

          <section>
            <h2>4. Payment Terms</h2>
            <p>For the services provided, payment will be due as specified in the service agreement. Failure to make payment may result in suspension or termination of services.</p>
          </section>

          <section>
            <h2>5. Intellectual Property</h2>
            <p>All intellectual property rights related to the services, including website designs and custom code, are owned by Digime Web Solutions unless otherwise agreed in writing.</p>
          </section>

          <section>
            <h2>6. Limitation of Liability</h2>
            <p>Digime Web Solutions is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services or from any failure or error in the services provided.</p>
          </section>

          <section>
            <h2>7. Termination</h2>
            <p>Either party may terminate this agreement with written notice if there is a breach of these Terms. Upon termination, you must cease using our services, and we may remove any content associated with your account.</p>
          </section>

          <section>
            <h2>8. Privacy Policy</h2>
            <p>By using our services, you agree to our Privacy Policy, which describes how we collect, use, and protect your personal information. You can view our Privacy Policy <a href="/privacy-policy">here</a>.</p>
          </section>

          <section>
            <h2>9. Modifications to Terms</h2>
            <p>We may update these Terms and Conditions from time to time. All changes will be posted on this page with an updated effective date. We encourage you to review these Terms periodically.</p>
          </section>

          <section>
            <h2>10. Governing Law</h2>
            <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Digime Web Solutions operates, without regard to its conflict of law principles.</p>
          </section>

          <section>
            <h2>11. Contact Information</h2>
            <p>If you have any questions or concerns about these Terms and Conditions, please contact us at <a href="mailto:support@digime.us">support@digime.us</a>.</p>
          </section>
        </article>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
