import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Home from './pages/Home';

const App = () => {
  return (
    <Router>
      <Routes>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<Terms />} />
      <Route path="/" element={<Home />} />
      {/* other routes */}
      </Routes>
    </Router>
  );
};

export default App;
